import { getApps, initializeApp } from "firebase/app";
import {
  EmailAuthProvider,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updatePassword as firebaseUpdatePassword,
  UserCredential,
} from "firebase/auth";
import Cookies from "js-cookie";
import { API_HOST, API_HOST_V2, parseResponseBody } from "./apiHelpers";

const firebaseConfig = {
  apiKey: "AIzaSyAHW6Mi1JJWuxLBBPQHWt9Iy_lDbkkHcM8",
  authDomain: "shopcash-production.firebaseapp.com",
  databaseURL: "https://shopcash-production.firebaseio.com",
  projectId: "shopcash-production",
  storageBucket: "shopcash-production.appspot.com",
  messagingSenderId: "728964503468",
  appId: "1:728964503468:web:979acd8701c43751fd8c09",
};

const BASE_AUTH_URL =
  process.env.APP_ENV == "development" ? API_HOST : `/kong/sc/shopcash/v1`;

export const BASE_AUTH_URL_V2 =
  process.env.APP_ENV == "development" ? API_HOST_V2 : `/kong/sc/shopcash/v2`;

export const createTokens = (
  firebaseIdToken: string,
  siteCode: string,
  language = "en"
): Promise<AuthTokens> => {
  const data = {
    data: {
      siteCode: siteCode,
      language: language,
    },
  };
  return fetch(`${BASE_AUTH_URL}/auth`, {
    method: "POST",
    credentials: "include",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${firebaseIdToken}`,
    }),
    body: JSON.stringify(data),
  })
    .then((r: Response) => r.json())
    .then((body: AuthResponse) =>
      parseResponseBody<AuthResponse, AuthTokens>(body)
    )
    .then((authTokens: AuthTokens) => {
      Cookies.set("isLoggedIn", true);
      return authTokens;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};

// Refresh Token is saved as http cookie. So no need to pass
// Browser will send refresh token to API (if there is)
export const refreshTokens = (): Promise<AuthTokens> => {
  const isLoggedIn = Cookies.get("isLoggedIn");
  if (isLoggedIn === "true") {
    return fetch(`${BASE_AUTH_URL}/auth/refresh`, {
      method: "POST",
      credentials: "include",
    })
      .then((r: Response) => r.json())
      .then((body: AuthRefreshResponse) =>
        parseResponseBody<AuthRefreshResponse, AuthTokens>(body)
      )
      .then((authTokens: AuthTokens) => {
        Cookies.set("isLoggedIn", true);
        return authTokens;
      })
      .catch((e) => {
        console.error(e);
        return null;
      });
  } else {
    return Promise.resolve(null);
  }
};

const logoutFromShopcash = async () => {
  return fetch(`${BASE_AUTH_URL}/auth/sign-out`, {
    method: "POST",
    credentials: "include",
  });
};

export const initFirebase = (): void => {
  if (!getApps().length) {
    initializeApp(firebaseConfig);
  }
};

export const loginViaGoogle = (): Promise<UserCredential> => {
  const provider = new GoogleAuthProvider();
  provider.addScope("email");

  const auth = getAuth();
  auth.useDeviceLanguage();
  return signInWithPopup(auth, provider);
};

export const loginViaFacebook = (): Promise<UserCredential> => {
  const provider = new FacebookAuthProvider();
  provider.addScope("email");
  const auth = getAuth();
  auth.useDeviceLanguage();
  return signInWithPopup(auth, provider);
};

export const loginViaEmailPassword = async (
  email: string,
  password: string
): Promise<UserCredential> => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

export const signupViaEmailPassword = async (
  request: FirebaseSignUpViaPasswordRequest
): Promise<UserCredential> => {
  const body = {
    data: request,
  };
  return fetch(`${BASE_AUTH_URL_V2}/auth/sign-up`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(body),
    headers: new Headers({
      "Content-Type": "application/json",
    }),
  })
    .then((r) => r.json())
    .then(() => {
      const auth = getAuth();
      return signInWithEmailAndPassword(auth, request.email, request.password);
    });
};

export const resetPassword = async (
  email: string,
  locale: string
): Promise<void> => {
  const auth = getAuth();
  auth.languageCode = locale;
  return sendPasswordResetEmail(auth, email);
};

// todo: add type
export const logout = async () => {
  Cookies.remove("isLoggedIn");
  const auth = getAuth();
  return signOut(auth).then(() => {
    return logoutFromShopcash();
  });
};

export const generateOtp = (
  email: string,
  language = "en"
): Promise<Response> => {
  // prettier-ignore
  const data = {
    "data": {
      "email": email,
      "language": language
    }
  }

  return fetch(`${BASE_AUTH_URL}/otp/generate`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const generateOtpV2 = (
  email: string,
  action: string,
  language = "en",
  accessToken?: string
): Promise<Response> => {
  // prettier-ignore
  const data = {
    "data": {
      "email": email,
      "action": action,
      "language": language
    }
  }

  return fetch(`${BASE_AUTH_URL_V2}/otp/generate`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    }),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const generateOtpV2ForPhoneVerification = (
  phoneNumber: string,
  language = "en",
  accessToken: string
): Promise<Response> => {
  // prettier-ignore
  const data = {
    "data": {
      "phone_number": phoneNumber,
      "action": "update_phone_number",
      "language": language
    }
  }

  return fetch(`${BASE_AUTH_URL_V2}/otp/generate`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const verifyOtp = (email: string, otp: string): Promise<Response> => {
  // prettier-ignore
  const data = {
    "data": {
      "email": email,
      "otp": otp
    }
  }

  return fetch(`${BASE_AUTH_URL}/otp/verify`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const verifyOtpV2 = (
  otp: string,
  otpRequestId: string
): Promise<Response> => {
  // prettier-ignore
  const data = {
    "data": {
      "otpRequestId": otpRequestId,
      "otp": otp
    }
  }

  return fetch(`${BASE_AUTH_URL_V2}/otp/verify`, {
    method: "POST",
    headers: new Headers({ "Content-Type": "application/json" }),
    credentials: "include",
    body: JSON.stringify(data),
  });
};

export const validatePassword = async (
  email: string,
  currentPassword: string
) => {
  const auth = getAuth();
  const user = await auth.currentUser;
  const credential = await EmailAuthProvider.credential(email, currentPassword);
  return reauthenticateWithCredential(user, credential);
};

export const updatePassword = async (newPassword: string) => {
  const user = await getAuth().currentUser;

  return firebaseUpdatePassword(user, newPassword);
};
