export enum Source {
  shopcash = "ShopCash",
}

export enum WebEngageCategoryId {
  AllStores = "all-stores",
}

export enum WebEngagePageView {
  homePage = "Home Page View",
  store = "Store View",
  category = "Category View",
  referral = "Referral Page View",
  howToEarn = "How To Earn Cashback Page View",
  deals = "All Deals page View",
}

export enum WebEngageClickEvent {
  handoff = "Handoff",
  homePageBannerClick = "Home Page Banner Click",
  trendingStoreClick = "Trending Store Click",
  homePageDealsClick = "Home Page Deals Click",
  languageClick = "Language Change",
  emailOptInClick = "Email Optin Click",
  dealsPageDealsClick = "All Deals page Click",
}

export enum WebEngageDealType {
  WithCoupon = "With Coupon",
  WithoutCoupon = "Without Coupon",
  CouponAgnostic = "Coupon Agnostic",
  AllDeals = "All Deals",
}

export enum WebEngageUserEventType {
  Login = "Login",
  Logout = "Logout",
  Signup = "Signup",
  Default = "",
}

export enum WebEngageUserEventMode {
  Facebook = "facebook",
  Google = "google",
  Email = "email",
}

export interface WebEngageTracking {
  trackWebEngageClickEvent(eventName: WebEngageClickEvent, value?): void;
  trackWebEngagePageView(eventName: WebEngagePageView, value?): void;
  trackWebEngageUserEvent(
    userEventAttributes: WebEngageUserEventAttributes
  ): void;
}

export interface WebEngageUserEventAttributes {
  type: WebEngageUserEventType;
  mode?: WebEngageUserEventMode;
}

export enum WebEngageUserAttributes {
  fullName = "Full Name",
  firstName = "we_first_name",
  gender = "we_gender",
  dob = "we_birth_date",
  email = "we_email",
  referralCode = "Referral Code",
  userCreatedAt = "Account Creation Date",
  emailNotificationsEnabled = "we_email_opt_in",
  wegoUserHash = "Wego User Hash",
  siteCode = "Site Code",
  appType = "App Type",
  currency = "Currency",
  language = "Language",
}
