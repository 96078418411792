import { sendScrimmageEvent } from "@api/scrimmage";

export const sendRefreshTokenToChromeExtension = (
  extensionId: string,
  accessToken: string,
  refreshToken: string
): void => {
  window?.chrome?.runtime?.sendMessage(
    extensionId,
    { refreshToken },
    (response) => {
      if (response === "chrome extension login success") {
        sendScrimmageEvent(accessToken, "Chrome_Extension_Added");
      }
    }
  );
};
