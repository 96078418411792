import { AuthProvider } from "@context/authentication";
import { IdentityProvider } from "@context/identity";
import { GenzoProvider } from "@context/tracking/genzo";
import { GtmProvider } from "@context/tracking/gtm";
import { WebEngageProvider } from "@context/tracking/webEngage";
import { genzoDebug } from "@helpers/development";
import { getGtmId } from "@helpers/tracking";
import "@styles/globals.css";
import type { NextPage } from "next";
import type { AppProps as NextAppProps } from "next/app";
import Script from "next/script";
import { useEffect } from "react";

declare global {
  interface Window {
    genzoDebug(enable?: boolean): void;
  }
}

interface AppPageProps {
  siteConfig: SiteConfig;
  translations: Translations;
  disableGtm?: boolean;
  isOnAuthenticatedPage?: boolean;
  redirectTo?: string;
  isDumbPage?: boolean;
}

// https://stackoverflow.com/a/67464299/9701458
// modified version - allows for custom pageProps type
type AppProps<P> = {
  pageProps: P;
} & Omit<NextAppProps<P>, "pageProps">;

const Shopcash: NextPage<AppProps<AppPageProps>> = ({
  Component,
  pageProps,
}) => {
  useEffect(() => {
    if ("serviceWorker" in navigator) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigator.serviceWorker.register("/sw.js");
    }

    window.genzoDebug = (enable = false): void => {
      genzoDebug(enable);
    };
  }, []);

  const gtmId = pageProps.disableGtm
    ? null
    : getGtmId(pageProps?.siteConfig?.env);

  return (
    <>
      {gtmId && (
        <Script
          id="gtm"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `<!-- Google Tag Manager -->
                (function (w, d, s, l, i) {
                  w[l] = w[l] || [];
                  w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
                  var f = d.getElementsByTagName(s)[0],
                    j = d.createElement(s),
                    dl = l != "dataLayer" ? "&l=" + l : "";
                  j.async = true;
                  j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
                  f.parentNode.insertBefore(j, f);
                })(window, document, "script", "dataLayer", "${gtmId}");
              <!-- End Google Tag Manager -->
              `,
          }}
        />
      )}

      {gtmId && (
        <noscript>
          <iframe
            src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
            height="0"
            width="0"
            style={{ display: "none", visibility: "hidden" }}
          />
        </noscript>
      )}

      {pageProps?.isDumbPage ? (
        <Component {...pageProps} />
      ) : (
        <IdentityProvider>
          <AuthProvider
            isOnAuthenticatedPage={pageProps?.isOnAuthenticatedPage}
            redirectTo={pageProps?.redirectTo}
            siteCode={pageProps?.siteConfig?.siteCode}
            locale={pageProps?.siteConfig?.locale}
          >
            <GenzoProvider
              siteConfig={pageProps?.siteConfig}
              isOnAuthenticatedPage={pageProps?.isOnAuthenticatedPage}
            >
              <GtmProvider siteConfig={pageProps?.siteConfig}>
                <WebEngageProvider siteConfig={pageProps?.siteConfig}>
                  <Component {...pageProps} />
                </WebEngageProvider>
              </GtmProvider>
            </GenzoProvider>
          </AuthProvider>
        </IdentityProvider>
      )}
    </>
  );
};

export default Shopcash;
