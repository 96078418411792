import { getWithdrawalStatusName } from "@helpers/account";
import { dateDisplayFormat } from "@helpers/date";

export const mapWithdrawalsApiData = (
  withdrawals: Withdrawal[],
  locale: string
) => {
  if (!withdrawals) return;
  const cloneWithdrawals = JSON.parse(JSON.stringify(withdrawals));

  cloneWithdrawals.map((withdrawal: Withdrawal) => {
    withdrawal.formattedDate = dateDisplayFormat(withdrawal.date, locale);
    withdrawal.statusName = getWithdrawalStatusName(withdrawal.status);

    return withdrawal;
  });

  return cloneWithdrawals;
};

export const mapAppType = (appType: string): string => {
  switch (appType) {
    case "mweb":
      return "MOBILE_WEB_APP";
    case "desktop":
      return "WEB_APP";
  }

  return "";
};
