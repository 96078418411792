const getExpiry = (name: string): number => {
  const minutesToMidnight =
    (new Date().setHours(24, 0, 0, 0) - Date.now()) / 60 / 1000;

  switch (name) {
    case "favoritedStoreId":
      // 1 day
      return 1 * 24 * 60 * 60 * 1000;
      break;
    case "client_id":
      // 2 years
      return 365 * 2 * 24 * 60 * 60 * 1000;
      break;
    case "session_id":
      if (minutesToMidnight < 30) {
        return minutesToMidnight * 60 * 1000;
      }
      return 30 * 60 * 1000;
    default:
      // 100 days
      return 100 * 24 * 60 * 60 * 1000;
      break;
  }
};

export const setCookie = (cname: string, cvalue: string | boolean): void => {
  const d = new Date();
  d.setTime(d.getTime() + getExpiry(cname));
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
};

export const getCookie = (cname: string): string => {
  const name = cname + "=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname: string): void => {
  const d = new Date();
  d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000);
  const expires = "expires=" + d.toUTCString();
  document.cookie = cname + '="";' + expires + ";path=/";
};
