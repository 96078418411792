import { getCountryCode } from "@api/genzo";
import { useAuth } from "@context/authentication";
import { useIdentity } from "@context/identity";
import { mapToDataLayerFormat } from "@helpers/gtm/gtmPageViewBuilders";
import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";

export const GtmContext = createContext({} as GtmTracking);

interface GtmProviderProps {
  children: React.ReactNode;
  siteConfig: SiteConfig;
}

interface GtmGenericPageViewData {
  device_type: string;
  user_country_code: string;
  domain: string;
  event: string;
  session_id: string;
  client_id: string;
  user_id?: string;
  ts_code: string;
  locale: string;
  currency_code: string;
  site_code: string;
  url: string;
}

const GtmProvider: React.FC<GtmProviderProps> = ({ children, siteConfig }) => {
  const { user, isAuthenticating } = useAuth();
  const { getClientId, sessionIdentity } = useIdentity();
  const [pageName, setPageName] = useState<string>("");
  const [extraPageData, setExtraPageData] = useState<null | GtmExtraData>();
  const [userCountryCode, setUserCountryCode] = useState<string>("");

  const { query } = useRouter();
  const isMounted = React.useRef(true);

  useEffect(() => {
    const getUserCountryCode = async (): Promise<void> => {
      if (!userCountryCode) {
        const countryCode = await getCountryCode();

        if (isMounted.current) {
          setUserCountryCode(countryCode);
        }
      }
    };

    getUserCountryCode();

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      !isAuthenticating &&
      pageName &&
      siteConfig &&
      userCountryCode &&
      extraPageData
    ) {
      const gtmPageViewData = mapGtmPageViewData(
        pageName,
        siteConfig,
        userCountryCode,
        extraPageData,
        user
      );

      window.dataLayer?.push(gtmPageViewData);
      setExtraPageData(null);
    }
  }, [pageName, isAuthenticating, siteConfig, userCountryCode, extraPageData]);

  const mapGtmPageViewData = (
    pageName: string,
    siteConfig: SiteConfig,
    userCountryCode: string,
    extraData: GtmExtraData,
    user?: User
  ) => {
    const baseData: GtmGenericPageViewData = {
      user_country_code: userCountryCode,
      device_type: siteConfig.isMobile ? "MOBILE WEB" : "DESKTOP",
      event: "pageChanged",
      domain: siteConfig.domain,
      session_id: sessionIdentity.getId(),
      client_id: getClientId(),
      user_id: user?.userHash,
      ts_code: query?.ts_code as string,
      locale: siteConfig.locale,
      currency_code: siteConfig.currencyCode,
      site_code: siteConfig.siteCode,
      url: window.location.href,
    };

    return { ...baseData, ...mapToDataLayerFormat(pageName, extraData) };
  };

  const handlePageViewTrack = (
    pageName: string,
    extraData: GtmExtraData
  ): void => {
    setExtraPageData(extraData);
    setPageName(pageName);
  };

  return (
    <GtmContext.Provider
      value={{
        trackGtmPageView: handlePageViewTrack,
      }}
    >
      {children}
    </GtmContext.Provider>
  );
};

export default GtmProvider;
