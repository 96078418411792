import { deleteCookie, getCookie, setCookie } from "@helpers/cookie";

export const genzoDebug = (enable: boolean): void => {
  enable ? setCookie("genzoDebug", enable) : deleteCookie("genzoDebug");
};

export const displayGenzoInConsole = (
  eventType: string,
  eventValue:
    | GenzoPageView
    | GenzoEventAction
    | GenzoSessionEvent
    | GenzoUserEvent
    | GenzoHandoverEvent
    | GenzoHandoffEvent
): void => {
  if (getCookie("genzoDebug")) {
    console.log(
      `%cGenzo%c${eventType}%o`,
      "color: #fff; background-color: #2a2961;padding: 2px 8px; border-radius: 4px; margin-right: 8px",
      "color: #373946; background-color: #f4f2f7;padding: 2px 8px; border-radius: 4px; margin-right: 8px",
      eventValue
    );
  }
};
