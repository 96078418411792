import {
  trackEventAction as sendEventAction,
  trackPageView as sendPageView,
  trackSessionEvent as sendSessionEvent,
  trackUserEvent as sendUserEvent,
} from "@api/genzo";
import { useAuth } from "@context/authentication";
import { useIdentity } from "@context/identity";
import { getTimezoneOffset, toIsoString } from "@helpers/date";
import { mapAppType } from "@helpers/mapper";
import cuid from "cuid";
import { useRouter } from "next/router";
import React, { createContext, useEffect, useReducer, useState } from "react";

export const GenzoContext = createContext({} as GenzoTracking);

const eventsReducer = (
  state: GenzoEventAction[],
  action: {
    type: string;
    event: GenzoEventAction;
  }
) => {
  switch (action.type) {
    case "add":
      return [...state, action.event];
    case "remove":
      return state.filter((event) => event.id != action.event.id);
    default:
      throw new Error();
  }
};
interface GenzoProviderProps {
  children: React.ReactNode;
  siteConfig: SiteConfig;
  isOnAuthenticatedPage?: boolean;
}

const GenzoProvider: React.FC<GenzoProviderProps> = ({
  siteConfig,
  children,
  isOnAuthenticatedPage,
}) => {
  const { user, isAuthenticating } = useAuth();
  const [genzoClient, setGenzoClient] = useState<GenzoClient>();
  const [genzoPage, setGenzoPage] = useState<GenzoPage>();
  const [pageViewId, setPageViewId] = useState<string>();
  const router = useRouter();
  const { extension_id } = router.query;

  const { getClientId, sessionIdentity } = useIdentity();

  const generateClient = (sessionId: string): GenzoClient => {
    const client = {
      id: getClientId(),
      sessionId: sessionId,
      timezone: getTimezoneOffset(),
      userHash: user?.userHash,
      appType: mapAppType(siteConfig.appType),
    };

    return client;
  };

  const handlePageViewTrack = (genzoPage: GenzoPage): void => {
    const newPageViewId = cuid();
    setPageViewId(newPageViewId);
    setGenzoPage({
      ...genzoPage,
      locale: siteConfig.locale,
      siteCode: siteConfig.siteCode,
    });
  };

  const handleSessionIdRenew = (sessionIdentity: SessionIdentity): void => {
    if (sessionIdentity.isNew) {
      const client = generateClient(sessionIdentity.getId());
      setGenzoClient(client);
      handleSessionEvent(client);
      sessionIdentity.clearIndicator();
    }
  };

  useEffect(() => {
    handleSessionIdRenew(sessionIdentity);
  }, [sessionIdentity.isNew]);

  useEffect(() => {
    if (!isAuthenticating && genzoPage) {
      const client = generateClient(sessionIdentity.getId());
      setGenzoClient(client);
      const pageViewRequest: GenzoPageView = {
        id: pageViewId,
        page: genzoPage,
        client: client,
        createdAt: toIsoString(new Date()),
        ...(!!extension_id && { eventId: extension_id as string }),
      };

      const shouldSendPageView = !(isOnAuthenticatedPage && !user);

      // if on authenticated page, send page view event ONLY if user object is available
      // if not on authenticated page, just send page view event
      if (shouldSendPageView) {
        sendPageView(pageViewRequest);
      }
    }
  }, [isAuthenticating, genzoPage]);

  const handleSessionEvent = (client: GenzoClient): void => {
    if (!genzoPage) return;

    const sessionEventRequest: GenzoSessionEvent = {
      id: pageViewId,
      page: genzoPage,
      client: client,
      createdAt: toIsoString(new Date()),
    };
    sendSessionEvent(sessionEventRequest);
  };

  const initialEvents: Array<GenzoEventAction> = [];

  const [events, dispatch] = useReducer(eventsReducer, initialEvents);

  const handleEventTrack = (genzoEvent: GenzoEvent): void => {
    if (!pageViewId) return;

    const eventActionRequest: GenzoEventAction = {
      event: { ...genzoEvent, pageViewId },
      id: cuid(),
      createdAt: toIsoString(new Date()),
    };

    dispatch({ type: "add", event: eventActionRequest });
  };

  useEffect(() => {
    if (!isAuthenticating && genzoClient && events.length > 0) {
      let client = genzoClient;

      if (user && !client.userHash) {
        client = generateClient(client.sessionId);
        setGenzoClient(client);
      }

      const eventActionRequest: GenzoEventAction = {
        ...events[0],
        client: client,
      };
      sendEventAction(eventActionRequest);
      dispatch({ type: "remove", event: events[0] });
    }
  }, [isAuthenticating, genzoClient, events]);

  const handleUserEventTrack = (type: string): void => {
    if (!genzoPage || !genzoClient) return;

    let client = genzoClient;

    if (type == "USER_LOGIN") {
      client = generateClient(genzoClient.sessionId);
      setGenzoClient(client);
    }

    const campaign = {};
    const userEventRequest: GenzoUserEvent = {
      id: cuid(),
      client: client,
      page: genzoPage,
      campaign: campaign,
      createdAt: toIsoString(new Date()),
      userEventType: type,
      status: null,
    };

    sendUserEvent(userEventRequest);
  };

  return (
    <GenzoContext.Provider
      value={{
        trackPageView: handlePageViewTrack,
        trackEvent: handleEventTrack,
        trackUserEvent: handleUserEventTrack,
        page: genzoPage,
        client: genzoClient,
      }}
    >
      {children}
    </GenzoContext.Provider>
  );
};

export default GenzoProvider;
