import { mapWithdrawalsApiData } from "@helpers/mapper";
import { API_HOST, parseResponseBody } from "./apiHelpers";

export const updateUserPreferences = (
  accessToken: string,
  emailPreference: boolean
): Promise<Preferences> => {
  return fetch(`${API_HOST}/account/preferences`, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({
      data: { emailNotificationsEnabled: emailPreference },
    }),
  })
    .then((r: Response) => r.json())
    .then((body: PreferencesResponse) =>
      parseResponseBody<PreferencesResponse, Preferences>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getAccountDetails = (accessToken: string): Promise<User> => {
  return fetch(`${API_HOST}/account`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then((body: AccountDetailsResponse) =>
      parseResponseBody<AccountDetailsResponse, User>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getPayouts = (
  accessToken: string,
  locale = "en",
  page = 1,
  perPage = 30
): Promise<PayoutsParseResponse> => {
  return fetch(
    `${API_HOST}/account/payouts?language=${locale}&page=${page}&perPage=${perPage}`,
    {
      headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
    }
  )
    .then((r: Response) => r.json())
    .then(
      (body: PayoutsResponse): PayoutsParseResponse => ({
        payouts: body["data"],
        pagination: body["meta"],
      })
    )
    .catch((e) => {
      console.error(e);
      return { payouts: [], pagination: {} };
    });
};

export const getWithdrawals = (
  accessToken: string,
  locale: string,
  page: number,
  perPage: number
): Promise<WithdrawalsParseResponse> => {
  const params = `?page=${page}&perPage=${perPage}&language=${locale}`;
  return fetch(`${API_HOST}/account/withdrawals${params}`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then(
      (body: WithdrawalsResponse): WithdrawalsParseResponse => ({
        withdrawals: mapWithdrawalsApiData(body["data"], locale),
        pagination: body["meta"],
      })
    )
    .catch((e) => {
      console.error(e);
      return { withdrawals: [], pagination: {} };
    });
};

export const getClickHistory = (
  accessToken: string,
  locale: string,
  page: number,
  perPage: number
): Promise<ClicksParseResponse> => {
  const params = `?page=${page}&perPage=${perPage}`;
  return fetch(`${API_HOST}/account/clicks${params}&language=${locale}`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then(
      (body: ClicksResponse): ClicksParseResponse => ({
        clicks: body["data"],
        pagination: body["meta"],
      })
    )
    .catch((e) => {
      console.error(e);
      return { clicks: [], pagination: {} };
    });
};

export const getFavouriteStores = (
  accessToken: string,
  language = "en",
  siteCode: string,
  perPage = 30
): Promise<FavouriteStoresParseResponse> => {
  const params = `?page=1&perPage=${perPage}&language=${language}&siteCode=${siteCode}`;
  return fetch(`${API_HOST}/account/favourite-stores${params}`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then(
      (body: FavouriteStoresResponse): FavouriteStoresParseResponse => ({
        favouriteStores: body["data"],
        pagination: body["meta"],
      })
    )
    .catch((e) => {
      console.error(e);
      return { favouriteStores: [], pagination: {} };
    });
};

export const updateProfile = (
  accessToken: string,
  profile: Profile
): Promise<Profile> => {
  return fetch(`${API_HOST}/account/profile`, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({ data: profile }),
  })
    .then((r: Response) => r.json())
    .then((body: ProfileResponse) =>
      parseResponseBody<ProfileResponse, Profile>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const updateProfilePhoneNumber = (
  accessToken: string,
  otpRequestId: string
): Promise<Profile> => {
  return fetch(`${API_HOST}/account/profile/phone_number`, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({
      data: { otpRequestId },
    }),
  })
    .then((r: Response) => r.json())
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getBankAccount = (accessToken: string): Promise<BankAccount> => {
  return fetch(`${API_HOST}/bank-account`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then((body: BankAccountResponse) =>
      parseResponseBody<BankAccountResponse, BankAccount>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};

export const getStoreViews = async (
  accessToken: string,
  siteCode: string,
  maxAge = 30,
  page = 1,
  perPage = 10
): Promise<StoreViewsParseResponse> => {
  const params = `?page=${page}&perPage=${perPage}&maxAge=${maxAge}&siteCode=${siteCode}`;

  return fetch(`${API_HOST}/account/store-views${params}`, {
    headers: new Headers({ Authorization: `Bearer ${accessToken}` }),
  })
    .then((r: Response) => r.json())
    .then(
      (body: StoreViewsResponse): StoreViewsParseResponse => ({
        storeViews: body["data"],
        pagination: body["meta"],
      })
    )
    .catch((e) => {
      console.error(e);
      return { storeViews: [], pagination: {} };
    });
};

export const updateUserAccount = async (
  accessToken: string,
  data: UpdateUserAccountRequest
): Promise<void> => {
  return fetch(`${API_HOST}/account`, {
    method: "PUT",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({
      data: data,
    }),
  }).catch((e) => {
    console.error(e);
    return null;
  });
};

export const updateProfileEmailName = (
  accessToken: string,
  updates: { name: string; email?: string },
  otpRequestId: string
): Promise<Profile> => {
  return fetch(`${API_HOST}/account/profile/email`, {
    method: "PATCH",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    body: JSON.stringify({
      data: { ...updates, otpRequestId },
    }),
  })
    .then((r: Response) => r.json())
    .then((body: ProfileResponse) =>
      parseResponseBody<ProfileResponse, Profile>(body)
    )
    .catch((e) => {
      console.error(e);
      return null;
    });
};
