const months = {
  en: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  ar: [
    "كانون الثاني",
    "شهر فبراير",
    "مارس",
    "أبريل",
    "مايو",
    "يونيو",
    "يوليو",
    "أغسطس",
    "سبتمبر",
    "اكتوبر",
    "شهر نوفمبر",
    "ديسمبر",
  ],
};

const translation = {
  en: { at: "at" },
  ar: { at: "الساعة" },
};

const prependZeroWhenSingleDigit = (digit: number): string => {
  return digit.toString().length === 1 ? `0${digit}` : `${digit}`;
};

export const getMonthLabel = (
  locale: string,
  month: number,
  type: "short" | "full"
): string => {
  const localizedMonth = months[locale][month];

  return locale === "en" && type === "short"
    ? localizedMonth.slice(0, 3)
    : localizedMonth;
};

export const getNextPaymentMonth = () => {
  const date = new Date();
  const monthAfterEndOfQuarter = (Math.floor(date.getMonth() / 3) + 1) * 3;
  const paymentMonth = monthAfterEndOfQuarter > 11 ? 1 : monthAfterEndOfQuarter;

  return getMonthLabel("en", paymentMonth, "short");
};

export const getDateToday = (
  type: "fullYear" | "shortMonth" | "fullMonth",
  locale = "en"
): string => {
  const date = new Date();

  switch (type) {
    case "fullYear":
      return date.getFullYear().toString();
      break;
    case "shortMonth":
      return getMonthLabel(locale, date.getMonth(), "short");
      break;
    case "fullMonth":
      return getMonthLabel(locale, date.getMonth(), "full");
      break;

    default:
      break;
  }
};

export const UTCtoLocalTime = (date: string) => {
  // Format from API "2020-11-21T03:54:14" UTC string
  // convert to UTC date object
  const givenDate = new Date(date);

  // convert to UTC timestamp
  const timestamp = Date.UTC(
    givenDate.getFullYear(),
    givenDate.getMonth(),
    givenDate.getDate(),
    givenDate.getHours(),
    givenDate.getMinutes(),
    givenDate.getSeconds()
  );

  // convert timestamp to date object to get local time
  return new Date(timestamp);
};

export const dateDisplayFormat = (date: string, locale = "en"): string => {
  // convert date into local time
  const givenDate = UTCtoLocalTime(date);

  const day = givenDate.getDate();
  const month = getMonthLabel(locale, givenDate.getMonth(), "short");
  const year = givenDate.getFullYear();
  const hours = givenDate.getHours();
  const formatHours = hours.toString().length === 1 ? `0${hours}` : hours;
  const minutes = givenDate.getMinutes();
  const formatMinutes =
    minutes.toString().length === 1 ? `0${minutes}` : minutes;

  // date format is DD Mmm YYYY at HH:MM
  return `${day} ${month} ${year} ${translation[locale]["at"]} ${formatHours}:${formatMinutes}`;
};

export const getDateDifferenceInMilliseconds = (date1: Date, date2: Date) => {
  const diffTime = Math.abs(date2.getTime() - date1.getTime());
  return diffTime;
};

export const getTimezoneOffset = () => {
  const z = (n: number) => {
    return (n < 10 ? "0" : "") + n;
  };
  let offset = new Date().getTimezoneOffset();
  const sign = offset < 0 ? "+" : "-";
  offset = Math.abs(offset);
  return sign + z((offset / 60) | 0) + z(offset % 60);
};

//  returns date in ISO Format with Time Zone
// Example: "2008-09-15T15:53:00+05:00"
export const toIsoString = (date) => {
  const tzo = -date.getTimezoneOffset();
  const dif = tzo >= 0 ? "+" : "-";
  const pad = (num) => {
    const norm = Math.floor(Math.abs(num));
    return (norm < 10 ? "0" : "") + norm;
  };
  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ":" +
    pad(tzo % 60)
  );
};

export const getLocalDateAndTimeSeperately = (
  date: string
): [string, string] => {
  // Format from API "2020-11-21T03:54:14" UTC string
  const convertedDate = UTCtoLocalTime(date); // use user's timezone

  const year = convertedDate.getFullYear();
  const month = prependZeroWhenSingleDigit(convertedDate.getMonth() + 1);
  const day = prependZeroWhenSingleDigit(convertedDate.getDate());

  const hours = prependZeroWhenSingleDigit(convertedDate.getHours());
  const minutes = prependZeroWhenSingleDigit(convertedDate.getMinutes());
  const seconds = prependZeroWhenSingleDigit(convertedDate.getSeconds());

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes}:${seconds}`;
  // date format is yyyy-mm-dd
  // time format is hh:mm:ss
  // [yyyy-mm-dd, hh:mm:ss]
  return [formattedDate, formattedTime];
};

export interface GetDateDiffType {
  value: number;
  unit: string;
}
export const getDateDiff = (date1: Date, date2: Date): GetDateDiffType => {
  const absDiffInSec = Math.abs(date1.getTime() - date2.getTime()) / 1000;
  let value: number, unit: string;

  if (absDiffInSec < 60) {
    value = absDiffInSec;
    unit = value == 1 ? "second" : "seconds";
  }

  if (absDiffInSec >= 60 && absDiffInSec < 60 * 60) {
    value = Math.floor(absDiffInSec / 60);
    unit = value == 1 ? "minute" : "minutes";
  }

  if (absDiffInSec >= 60 * 60 && absDiffInSec < 24 * 60 * 60) {
    value = Math.floor(absDiffInSec / (60 * 60));
    unit = value == 1 ? "hour" : "hours";
  }

  if (absDiffInSec >= 24 * 60 * 60) {
    value = Math.floor(absDiffInSec / (24 * 60 * 60));
    unit = value == 1 ? "day" : "days";
  }

  return {
    value: value,
    unit: unit,
  };
};
