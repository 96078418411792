import { API_HOST } from "./apiHelpers";

interface ScrimmageBody {
  [key: string]: string | number | boolean;
}

export const sendScrimmageEvent = (
  accessToken: string,
  dataType: string,
  body?: ScrimmageBody
): Promise<void> => {
  const data = {
    data: {
      dataType,
      body,
    },
  };
  return fetch(`${API_HOST}/gamification/events`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((r) => {
      return null;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};
