import { getAccountDetails } from "@api/account";
import {
  createTokens,
  initFirebase,
  loginViaEmailPassword as firebaseLoginViaEmailPassword,
  loginViaFacebook as firebaseLoginViaFacebook,
  loginViaGoogle as firebaseLoginViaGoogle,
  logout as logoutViaApi,
  refreshTokens,
  signupViaEmailPassword as firebaseSignupViaEmailPassword,
} from "@api/authentications";
import { createReferral } from "@api/referrals";
import { sendRefreshTokenToChromeExtension } from "@helpers/chromeExtension";
import { Auth } from "@shopcashTypes/auth";
import { getAuth } from "firebase/auth";
import { useRouter } from "next/router";
import React, { createContext, useEffect, useState } from "react";

initFirebase();

export const AuthContext = createContext({} as Auth);

interface AuthProviderProps {
  children: React.ReactNode;
  isOnAuthenticatedPage: boolean;
  redirectTo: string;
  siteCode: string;
  locale: string;
}

const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
  isOnAuthenticatedPage,
  siteCode,
  locale,
  redirectTo,
}) => {
  const router = useRouter();
  const [referralCode, setReferralCode] = useState<string>();
  const [hasUserLoggedIn, setHasUserLoggedIn] = useState<boolean>(false);

  const chromeExtensionId = (env) => {
    switch (env) {
      case "production":
        return "kjblmmkfofjokionfgolkolihfpbhenl";
      case "staging":
        return "oblnfngickfigjpbdckelpocloflmain";
      default:
        return "nlnhigpknkkpcdmeiaakmifeaicfphaf";
    }
  };

  const [auth, setAuth] = useState<Auth>({
    user: null,
    isAuthenticating: true,
    logout: () => null,
    refreshUser: () => null,
    setReferralCode,
    loginViaGoogle: () => null,
    loginViaFacebook: () => null,
    loginViaEmailPassword: () => null,
    signupViaEmailPassword: () => null,
  });

  const handleFailedAuthCheck = () => {
    setAuth({
      ...auth,
      user: null,
      isAuthenticating: true,
    });
    if (isOnAuthenticatedPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.replace(redirectTo || "/");
    }

    getFireBaseUserOnTokenChange();
  };

  const handleLogout = () => {
    logoutViaApi();

    setAuth((previousAuth) => ({
      ...previousAuth,
      user: null,
      isAuthenticating: false,
      accessToken: null,
      logout: () => null,
      refreshUser: () => null,
    }));

    // we send a message to chrome extension when a user logs out
    // so that we can also log out from the extension too
    window?.chrome?.runtime?.sendMessage(
      chromeExtensionId(process.env.APP_ENV),
      { logout: true }
    );

    if (isOnAuthenticatedPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      router.push(redirectTo || "/");
    }
  };

  const getUser = async (accessToken: string) => {
    const user = await getAccountDetails(accessToken);

    if (user) {
      setAuth((previousAuth) => ({
        ...previousAuth,
        isAuthenticating: false,
        user: user,
        accessToken: accessToken,
        logout: handleLogout,
        refreshUser: (accessToken) => getUser(accessToken),
      }));
      return user;
    } else {
      handleFailedAuthCheck();
    }
  };

  const checkReturningUser = async () => {
    const tokens = await refreshTokens();

    if (tokens) {
      getUser(tokens.accessToken);
    } else {
      handleFailedAuthCheck();
    }
  };

  useEffect(() => {
    if (auth?.accessToken && hasUserLoggedIn) {
      createReferral(referralCode, auth?.accessToken);
      setHasUserLoggedIn(false);
    }
  }, [auth?.accessToken, hasUserLoggedIn]);

  useEffect(() => {
    checkReturningUser();
  }, [isOnAuthenticatedPage]);

  const handleFirebaseLoginResponse = async (result) => {
    if (result.user) {
      setHasUserLoggedIn(true);
    }

    return result;
  };

  const rebindLoginHandler = () => {
    setAuth((previousAuth) => ({
      ...previousAuth,
      loginViaGoogle: () =>
        firebaseLoginViaGoogle().then(
          handleFirebaseLoginResponse,
          (error) => error
        ),
      loginViaFacebook: () =>
        firebaseLoginViaFacebook().then(
          handleFirebaseLoginResponse,
          (error) => error
        ),
      loginViaEmailPassword: (email: string, password: string) =>
        firebaseLoginViaEmailPassword(email, password).then(
          handleFirebaseLoginResponse,
          (error) => error
        ),
      signupViaEmailPassword: (request) =>
        firebaseSignupViaEmailPassword(request).then(
          handleFirebaseLoginResponse,
          (error) => error
        ),
    }));
  };

  useEffect(() => {
    rebindLoginHandler();
  }, [locale, referralCode]);

  const refreshAccessTokenInterval = React.useRef(null);

  const getFireBaseUserOnTokenChange = () => {
    const auth = getAuth();

    auth.onIdTokenChanged(async (firebaseUser) => {
      if (firebaseUser) {
        const idToken = await firebaseUser.getIdToken();
        const tokens = await createTokens(idToken, siteCode, locale);
        getUser(tokens.accessToken);

        // send refreshToken to chrome extension
        // so that we can log in there too
        sendRefreshTokenToChromeExtension(
          chromeExtensionId(process.env.APP_ENV),
          tokens.refreshToken,
          tokens.accessToken
        );
      } else {
        setAuth((previousAuth) => ({
          ...previousAuth,
          isAuthenticating: false,
        }));
      }
    });
  };

  useEffect(() => {
    if (auth?.accessToken && !refreshAccessTokenInterval.current) {
      const refreshAccessToken = async () => {
        const tokens = await refreshTokens();
        if (tokens) {
          setAuth((previousAuth) => ({
            ...previousAuth,
            accessToken: tokens.accessToken,
          }));
        } else {
          handleFailedAuthCheck();
        }
      };

      refreshAccessTokenInterval.current = setInterval(
        refreshAccessToken,
        30 * 60 * 1000 // every 30 minutes
      );
    }

    if (!refreshAccessTokenInterval.current) {
      getFireBaseUserOnTokenChange();
    }
  }, [auth?.accessToken]);

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
