import { API_HOST } from "./apiHelpers";

export const validateReferralCode = (
  referralCode: string,
  siteCode: string
): Promise<boolean> => {
  const params = [`referralCode=${referralCode}`, `siteCode=${siteCode}`].join(
    "&"
  );
  const url = `${API_HOST}/referral/validate?${params}`;

  return fetch(url)
    .then((r) => {
      if (r.ok) {
        // valid
        return true;
      } else {
        return r.json().then(() => {
          // invalid
          return false;
        });
      }
    })
    .catch(() => {
      // error
      return false;
    });
};

export const createReferral = (
  referralCode: string,
  accessToken: string
): Promise<void> => {
  // prettier-ignore
  const data = {
    "data": {
      "referralCode": referralCode || "SITEWIDE_SIGNUP_CAMPAIGN"
    }
  }

  return fetch(`${API_HOST}/referral`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    }),
    credentials: "include",
    body: JSON.stringify(data),
  })
    .then((r) => {
      return null;
    })
    .catch((e) => {
      console.error(e);
      return null;
    });
};
