const STAGING_API_HOST = "https://api.shopcashstaging.com/sc/shopcash/v1";
const STAGING_API_HOST_V2 = "https://api.shopcashstaging.com/sc/shopcash/v2";

export const INTERNAL_API_HOST =
  process.env.APP_ENV === "development"
    ? STAGING_API_HOST
    : `https://shopcash-alb.${process.env.APP_ENV}.us-east-1.bezurk.org/shopcash/v1`;

export const API_HOST =
  process.env.APP_ENV === "production"
    ? "https://api.shopcash.com/sc/shopcash/v1"
    : STAGING_API_HOST;

export const INTERNAL_API_HOST_V2 =
  process.env.APP_ENV === "development"
    ? STAGING_API_HOST
    : `https://shopcash-alb.${process.env.APP_ENV}.us-east-1.bezurk.org/shopcash/v2`;

export const API_HOST_V2 =
  process.env.APP_ENV === "production"
    ? "https://api.shopcash.com/sc/shopcash/v2"
    : STAGING_API_HOST_V2;

export const parseResponseBody = <TypeResponse, TypeDataResponse>(
  body: TypeResponse
): TypeDataResponse => body["data"];

export const INTERNAL_USER_AGENT = "ShopCash Whitebeard";

export const PLACE_SERVICES_HOST = "https://srv.wego.com/places";

export const parseListResponse = (body): ApiListResponse => ({
  data: body["data"],
  meta: body["meta"],
});
