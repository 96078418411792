export const getStatusStyleName = (status: number) => {
  switch (status) {
    case 1:
      return "confirmed";
      break;
    case 2:
      return "rejected";
      break;
    case 3:
      return "pending";
      break;

    default:
      return "";
      break;
  }
};

export const getStatusCode = (status: number) => {
  switch (status) {
    case 1:
      return "Confirmed";
      break;
    case 2:
      return "Rejected";
      break;
    case 3:
      return "Pending";
      break;

    default:
      return "";
      break;
  }
};

export const getWithdrawalStatusName = (status: number): string => {
  switch (status) {
    case 1:
      return "confirmed";
      break;
    case 2:
      return "withdrawal_declined";
      break;
    case 3:
      return "withdrawal_processing";
      break;

    default:
      return "";
      break;
  }
};
