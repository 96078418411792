import { displayGenzoInConsole } from "@helpers/development";
import Cookies from "js-cookie";

export const GENZO_API_HOST =
  process.env.APP_ENV === "production"
    ? "https://api.shopcash.com/genzo/v1/shopcash"
    : "https://api.shopcashstaging.com/genzo/v1/shopcash";

export const GENZO_API_HOST_V2 =
  process.env.APP_ENV === "production"
    ? "https://api.shopcash.com/genzo/v2"
    : "https://api.shopcashstaging.com/genzo/v2";

export const trackPageView = (pageView: GenzoPageView): void => {
  fetch(`${GENZO_API_HOST}/pages/view`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: pageView.id,
      page: {
        name: pageView.page.name,
        base_type: pageView.page.baseType,
        sub_type: pageView.page.subType,
        url: pageView.page.url,
        referrer_url: pageView.page.referrerUrl,
        site_code: pageView.page.siteCode,
        locale: pageView.page.locale,
        product: pageView.page.baseType,
      },
      client: {
        id: pageView.client.id,
        session_id: pageView.client.sessionId,
        user_hash: pageView.client.userHash,
        timezone: pageView.client.timezone,
      },
      external_services: {
        fb: {
          click: Cookies.get("_fbc") || null,
          pixel: Cookies.get("_fbp") || null,
        },
      },
      created_at: pageView.createdAt,
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("pageView", pageView);
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const trackEventAction = (eventAction: GenzoEventAction): void => {
  fetch(`${GENZO_API_HOST}/events/action`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: eventAction.id,
      event: {
        id: eventAction.event.pageViewId,
        category: eventAction.event.category,
        object: eventAction.event.object,
        action: eventAction.event.action,
        value: eventAction.event.value,
      },
      client: {
        user_hash: eventAction.client.userHash,
        timezone: eventAction.client.timezone,
      },
      created_at: eventAction.createdAt,
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("eventAction", eventAction);
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const trackSessionEvent = (sessionEvent: GenzoSessionEvent): void => {
  fetch(`${GENZO_API_HOST}/sessions/event`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: sessionEvent.id,
      page: {
        url: sessionEvent.page.url,
        referrer_url: sessionEvent.page.referrerUrl,
        site_code: sessionEvent.page.siteCode,
        locale: sessionEvent.page.locale,
      },
      client: {
        id: sessionEvent.client.id,
        session_id: sessionEvent.client.sessionId,
        user_hash: sessionEvent.client.userHash,
        timezone: sessionEvent.client.timezone,
        app_type: sessionEvent.client.appType,
      },
      created_at: sessionEvent.createdAt,
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("sessionEvent", sessionEvent);
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const trackUserEvent = (userEvent: GenzoUserEvent): void => {
  fetch(`${GENZO_API_HOST}/users/event`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: userEvent.id,
      user_event_type: userEvent.userEventType,
      status: userEvent.status,
      page: {
        url: userEvent.page.url,
        referrer_url: userEvent.page.referrerUrl,
        site_code: userEvent.page.siteCode,
        locale: userEvent.page.locale,
      },
      client: {
        id: userEvent.client.id,
        session_id: userEvent.client.sessionId,
        user_hash: userEvent.client.userHash,
        timezone: userEvent.client.timezone,
        app_type: userEvent.client.appType,
      },
      campaign: {
        name: userEvent.campaign.name,
        source: userEvent.campaign.source,
        channel: userEvent.campaign.channel,
        zone: userEvent.campaign.zone,
      },
      created_at: userEvent.createdAt,
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("userEvent", userEvent);
      }
    })
    .catch((e) => {
      console.error(e);
    });
};

export const trackHandover = async (
  handoverEvent: GenzoHandoverEvent
): Promise<boolean> => {
  return fetch(`${GENZO_API_HOST}/handovers/event`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: handoverEvent.id,
      client: {
        id: handoverEvent.client.id,
        session_id: handoverEvent.client.sessionId,
        user_hash: handoverEvent.client.userHash,
        timezone: handoverEvent.client.timezone,
        app_type: handoverEvent.client.appType,
      },
      store: {
        id: handoverEvent.store.id,
        name: handoverEvent.store.enName,
        type: handoverEvent.store.storeType.toString(),
      },
      created_at: handoverEvent.createdAt,
      provider: {
        type: "",
        code: "",
        name: "",
      },
      external_services: {
        fb: {
          click: Cookies.get("_fbc") || null,
          pixel: Cookies.get("_fbp") || null,
        },
      },
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("handoverEvent", handoverEvent);
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const trackHandoff = async (
  handoffEvent: GenzoHandoffEvent
): Promise<boolean> => {
  return fetch(`${GENZO_API_HOST}/handoffs/event`, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    credentials: "include",
    body: JSON.stringify({
      id: handoffEvent.id,
      handover_id: handoffEvent.handoverId,
      client: {
        id: handoffEvent.client.id,
        session_id: handoffEvent.client.sessionId,
        user_hash: handoffEvent.client.userHash,
        timezone: handoffEvent.client.timezone,
        app_type: handoffEvent.client.appType,
      },
      page: {
        url: handoffEvent.page.url,
        referrer_url: handoffEvent.page.referrerUrl,
        site_code: handoffEvent.page.siteCode,
        locale: handoffEvent.page.locale,
      },
      created_at: handoffEvent.createdAt,
    }),
  })
    .then((r) => {
      if (r.ok) {
        displayGenzoInConsole("handoffEvent", handoffEvent);
        return true;
      } else {
        return false;
      }
    })
    .catch((e) => {
      console.error(e);
      return false;
    });
};

export const getCountryCode = (): Promise<string> => {
  return fetch(`${GENZO_API_HOST_V2}/context`, {
    method: "GET",
    credentials: "include",
  })
    .then((r) => r.json())
    .then((data) => data.geo.country_code)
    .catch((e) => {
      console.error(e);
      return null;
    });
};
