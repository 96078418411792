import { getCookie, setCookie } from "@helpers/cookie";
import { throttle } from "@helpers/throttle";
import React, { createContext, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export const IdentityContext = createContext({} as IdentityContext);

interface IdentityProviderProps {
  children: React.ReactNode;
}

const IdentityProvider: React.FC<IdentityProviderProps> = ({ children }) => {
  const [isSessionIdNew, setIsSessionIdNew] = useState<boolean>(false);
  const [identity, setIdentity] = useState<IdentityContext>({
    isIdentifying: true,
    sessionIdentity: {
      getId: () => null,
      isNew: isSessionIdNew,
      clearIndicator: () => null,
    },
    getClientId: () => null,
  });

  const handleGetSessionId = (): string => {
    let sessionId = getCookie("session_id");

    if (!sessionId) {
      sessionId = uuidv4();
      setIsSessionIdNew(true);
    }

    setCookie("session_id", sessionId);
    return sessionId;
  };

  const handleGetClientId = (): string => {
    const clientId = getCookie("client_id") || uuidv4();
    setCookie("client_id", clientId);

    return clientId;
  };

  const handlePageActivityEvent = (): void => {
    handleGetClientId();
    handleGetSessionId();
  };

  const handleIdentityInitialization = (): void => {
    handleGetClientId();

    document.addEventListener("click", handlePageActivityEvent);
    document.addEventListener("scroll", throttle(handlePageActivityEvent, 500));
  };

  useEffect(() => {
    setIdentity({
      isIdentifying: false,
      sessionIdentity: {
        getId: handleGetSessionId,
        isNew: isSessionIdNew,
        clearIndicator: () => setIsSessionIdNew(false),
      },
      getClientId: handleGetClientId,
    });
    handleIdentityInitialization();
  }, []);

  useEffect(() => {
    setIdentity((previousIdentity) => ({
      ...previousIdentity,
      sessionIdentity: {
        getId: handleGetSessionId,
        isNew: isSessionIdNew,
        clearIndicator: () => setIsSessionIdNew(false),
      },
    }));
  }, [isSessionIdNew]);

  return (
    <IdentityContext.Provider value={identity}>
      {children}
    </IdentityContext.Provider>
  );
};

export default IdentityProvider;
