// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import configProcess from "config/process.json";

import * as Sentry from "@sentry/nextjs";
import { CaptureConsole } from "@sentry/integrations";

// we are getting it from process.json that was supposed to be for pm2, which we don't use
const APP_ENV = configProcess.apps[0].env.APP_ENV;

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

["production", "staging"].includes(APP_ENV) &&
  Sentry.init({
    environment: APP_ENV,
    dsn:
      SENTRY_DSN ||
      "https://0fceeb126aa244239da131f536a95374@o16074.ingest.sentry.io/5944366",
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0,
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
    ],
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
  });
