export const getGtmId = (env: string): string => {
  if (env === "production") {
    return "GTM-W8RS6X2";
  }

  return "GTM-K2PLPVF";
};

export const getWebEngageLicenseCode = (env: string): string => {
  if (env === "production") {
    return "~10a5cb5d1";
  }
  // Staging web engage id
  return "~99198a6a";
};
