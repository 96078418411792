import { StoreDetails } from "@shopcashTypes/storeDetails";

export const mapToDataLayerFormat = (
  pageName: string,
  extraData: GtmExtraData
) => {
  const pageDataLayer = {
    page_name: extraData.pageName,
    page_type: extraData.pageType,
    page_sub_type: extraData.pageSubType,
  };

  switch (pageName) {
    case "category":
      return {
        ...pageDataLayer,
        category_id: extraData.categoryId,
        category_name: extraData.categoryName,
      };
    case "refer":
      return {
        ...pageDataLayer,
        referral_code: extraData.referralCode,
        referral_description: extraData.referralDescription,
      };
    case "store":
      return {
        ...pageDataLayer,
        store_id: extraData.storeId,
        store_name: extraData.storeName,
        store_slug: extraData.storeSlug,
        cashback_text: extraData.cashbackText,
        provider: extraData.providerName,
      };
    case "handoff":
      return {
        ...pageDataLayer,
        store_name: extraData.storeName,
        store_id: extraData.storeId,
        store_slug: extraData.storeSlug,
        cashback_text: extraData.cashbackText,
        provider: extraData.provider,
        click_id: extraData.clickId,
        deal_id: extraData.dealId,
      };
    default:
      return pageDataLayer;
  }
};

export const getHomePageExtraData = (): GtmExtraDataCommon => ({
  pageName: "home",
  pageType: "home",
  pageSubType: "home",
});

export const getHowToEarnExtraData = (): GtmExtraDataCommon => ({
  pageName: "How to Earn",
  pageType: "account",
  pageSubType: "how-to-earn",
});

export const getDealsExtraData = (): GtmExtraDataCommon => ({
  pageName: "All Deals",
  pageType: "deals",
  pageSubType: "all_deals",
});

export const getCategoryExtraData = (
  category: Category
): GtmExtraDataCategory => ({
  pageName: category.enName,
  pageType: "categories",
  pageSubType: "categories",
  categoryId: category.id,
  categoryName: category.enName,
});

export const getReferAndEarnExtraData = (
  referralPayout,
  referralCode = ""
): GtmExtraDataReferAndEarn => ({
  pageName: "Referral",
  pageType: "referral",
  pageSubType: "referral",
  referralCode: referralCode,
  referralDescription: `Refer & Earn ${referralPayout.currencyCode} ${referralPayout.amount}`,
});

export const getStoreDetailsExtraData = (
  store: StoreDetails
): GtmExtraDataStoreDetails => ({
  pageName: store.enName,
  pageType: "store",
  pageSubType: "store",
  storeId: store.id,
  storeName: store.name,
  storeSlug: store.slug,
  cashbackText: store.cashbackText,
  providerName: "", // note: not available, yang la says leave empty for now
});

export const getHandoffExtraData = (
  deeplink: Deeplink,
  dealId?: string
): GtmExtraDataHandoff => {
  const { store, clickId } = deeplink;
  return {
    pageName: "Handoff",
    pageType: "handoff",
    pageSubType: "handoff",

    storeName: store.enName,
    storeId: store.id,
    storeSlug: store.slug,
    cashbackText: store.cashbackText,
    clickId: clickId,
    dealId: dealId || "",
    provider: "", // note: not available, yang la says leave empty for now,
  };
};
